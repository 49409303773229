import React from "react"

const Article = ({ picture, title, slug }) => (
  <div className="Article">
    <a href={`/articles/${slug}`}>
      <img src={picture} />
      <p className="articleText">{title}</p>
    </a>
  </div>
)

export const Articles = ({articles}) => (
  <div class="flexContainer">
    {articles.map((article, i) => (
      <Article {...article.data} key={i} />
    ))}
  </div>
)
