import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { Articles } from "../components/Watches/latest"
import { Container, Title } from "../components/Hero/hero.css"
import Newsletter from "../components/Newsletter/newsletter"
import BreadCrumb from "../components/Breadcrumb/breadcrumb"
import ReadMoreSVG from "../images/icons/readmore.inline.svg"

const shuffle = (array) => array.sort(() => Math.random() - 0.5);

const Latest = ({data}) => {
  const models = data.allBuilderModels
  const articles = models.article

  const page = models.watches[0]
  const pageModel = page.data.model
  const pageTitle = page.data.title
  const pageSummary = page.data.summary
  const pageImage = page.data.picture
  const pageDate = page.createdDate
  const pageLink = page.data.brand?.value?.data?.name ? `/brands/${page.data.brand?.value?.data?.name.toLowerCase().replace(/\s/g, '')}` : "/brands/"

  const watches = models.watches
  watches.splice(0, 1)

  const pageDescription = `Latest Releases - ${pageTitle}`

    return <>
    <Helmet>
      <title>Latest Releases - {pageModel}</title>
      <meta property="og:url" content={`https://www.watchesguild.com/latest/`} />
      <meta property="og:description" content={pageDescription} />
      <meta name="description" content={pageDescription} />
      <link rel="canonical" href={`https://www.watchesguild.com/latest/`}/>
      <script type="application/ld+json">
        {`
        {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
          {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/latest","name":"Latest Releases"}},
        ]}
        `}
      </script>
    </Helmet>
    <main
      style={{
        margin: `0 auto`,
      }} className="latestReleases stdPage"
    >
      <div className={pageImage ? `hasImage heroSection` : `heroSection`}>
        <div class="heroContainer">
          <BreadCrumb pageName={pageModel} />
          <Container className="imageContainer container">
            <Title>{pageModel}</Title>
            <div class="pageImage">
              <img src={pageImage} class="heroImage"/>
              <div class="pageDate">
                {new Intl.DateTimeFormat('en-HK', {month: "short"}).format(pageDate)}<br/>
                <b>{new Intl.DateTimeFormat('en-HK', {day: "2-digit"}).format(pageDate)}</b>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div class="container">
        <div class="pageDetails">
            <h2 class="pageTitle">{pageTitle}</h2>
            <div class="pageSummary">{pageSummary}</div>
            <div class="readMore">
              <a href={pageLink}>Read More
                <span>
                  <ReadMoreSVG/>
                </span>
              </a>
            </div>
        </div>
      </div>
      <div class="container">
        <div class="watches">
        {watches.map(watch => (
          <div class="watch">
            <div class="watchImage">
              <img src={watch.data.picture} />
              <span class="watchDate">
                {new Intl.DateTimeFormat('en-HK', {month: "short"}).format(watch.createdDate)}<br/>
                <b>{new Intl.DateTimeFormat('en-HK', {day: "2-digit"}).format(watch.createdDate)}</b>
              </span>
            </div>
            <div class="watchDetails">
              <h4 class="watchModel">{watch.data.model}</h4>
              {watch.data.reference && (
                <p><b>Reference:</b> {watch.data.reference}</p>
              )}
              {watch.data.caseDiameter && (
                <p>
                  <b>Case/Dial:</b> {watch.data.caseDiameter ? `${watch.data.caseDiameter}mm` : null}{watch.data.caseMaterial ? ` ${watch.data.caseMaterial}` : null}{watch.data.dialColour ? `, ${watch.data.dialColour}` : null}<br/>
                  {watch.data.waterResistance ? `${watch.data.waterResistance}` : null}
                </p>
              )}
              {watch.data.winding && (
                <p><b>Movement: </b>{watch.data.caliber ? `${watch.data.caliber}, ` : null}{watch.data.winding}</p>
              )}
              {watch.data.functions && (
                <p><b>Functions: </b>{watch.data.functions}{watch.data.powerReserve ? `, ${watch.data.powerReserve}h power reserve` : null}</p>
              )}
              {watch.data.strapBracelet && (
                <p><b>Strap: </b>{watch.data.strapBracelet}</p>
              )}
              {watch.data.style && (
                <p><b>Style:</b> {watch.data.style}</p>
              )}
              {watch.data.price && (
                <p><b>Price: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2}).format(watch.data.price)}</b></p>
              )}
              <a href={`/brands/${watch.data.brand?.value?.data?.name.toLowerCase().replace(/\s/g, '')}`} class="readMore">Read more
                <span>
                  <ReadMoreSVG/>
                </span>
              </a>
            </div>
          </div>
        ))}
        </div>
      </div>
      <div class="container">
        <div class="otherArticles Articles">
          <h4>{['Other ', <b>Articles</b>]}</h4>
          <Articles articles={articles} />
        </div>
      </div>
      <Newsletter placeholder="Your email here" submit="Subscribe" />
    </main>
  </>
  };

export default Latest

export const pageQuery = graphql`
  query{
    allBuilderModels {
      watches(limit: 5, sort: {createdDate: -1}, options: { includeRefs: true }) {
        data {
          title
          summary
          model
          reference
          price
          winding
          caseDiameter
          style
          date
          picture
          gallery
          brand
          jewels
          caliber
          functions
          powerReserve
          caseMaterial
          lugsWidth
          frequency
          additionalDetails
          waterResistance
          strapBracelet
          crystal
          limitedEdition
          dialColour
          bezel
          registers
          hourMarkers
        }
        createdDate
      }
      article(limit: 3, sort: {createdDate: -1}) {
        data {
          title
          slug
          summary
          picture
          writer
        }
        createdDate
      }
    }
  }
`;
